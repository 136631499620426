body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: #fff;
}

.site-layout-sider .logo {
    transition: 1s;
}

.site-form-item-icon {
    margin-right: 3px;
}

/* override styles when printing */
@media print {
    .site-layout-sider {
        display: none !important;
    }

    .site-page-header {
        display: none !important;
    }

    .reports-page-collapse {
        display: none !important;
    }

    table {
        table-layout: auto !important;
    }

    .ant-table-body {
        max-height: initial !important;
    }

    .ant-table-cell {
        padding: 2px !important;
    }
}
